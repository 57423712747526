<template>
  <div>
    <HomeSection />
    <RegisterSection />
    <ArticlesSection />
    <NewFooter />
  </div>
</template>

<script>
import HomeSection from "./HomeSection.vue";
import RegisterSection from "./RegisterSection.vue";
import ArticlesSection from "./ArticlesSection.vue";
import NewFooter from "./NewFooter.vue";
import { mapActions } from "vuex";

export default {
  components: {
    HomeSection,
    RegisterSection,
    ArticlesSection,
    NewFooter,
  },
    methods: {
    ...mapActions(["scrollTop"]),
  },
  watch: {
  },
  mounted() {
    this.scrollTop();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
